import './styles/app.scss';
import poetry from "./scripts/poetry";
import common from "./scripts/common";

const body = document.querySelector('body');

if (body.getAttribute('data-page') === 'poetry') {
  poetry.poetsSlider();
  poetry.poetsList();
}
if (body.getAttribute('data-page') === 'poem') {
  poetry.poetsList();
}

common.handleScrollToTop();
common.mobileMenu();
