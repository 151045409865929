export default {
  handleScrollToTop: function () {
    const topAnchor = document.querySelector('#top');
    const upButton = document.querySelector('.up');

    window.addEventListener('scroll', function () {
      const scrollY = window.scrollY;

      if (upButton) {
        if (scrollY > 200) {
            upButton.classList.add('active');
        } else {
            upButton.classList.remove('active');
        }
      }
    });

    upButton.addEventListener('click', function (e) {
      e.preventDefault();

      if (topAnchor) {
        window.scrollTo({
          top: topAnchor.offsetTop,
          behavior: 'smooth',
        });
      }
    });
	},
  mobileMenu: function () {
    const iconMobileMenu = document.querySelector('.menu-mobile');
    const navbar = document.querySelector('.navbar');
    const nameHome = document.querySelector('.background-wrapper');

    iconMobileMenu.addEventListener('click', () => {
      iconMobileMenu.classList.toggle('open');
      navbar.classList.toggle('open');
      nameHome.classList.toggle('open');
    });
  }
}
