export default {
    poetsSlider: function () {
        let currentPoetsIndex = 0;
        const prevPoetsButton = document.querySelector('.prev-poets');
        const nextPoetsButton = document.querySelector('.next-poets');
        const poetsSection = document.querySelectorAll('.poets-section');

        nextPoetsButton.addEventListener('click', () => {
          currentPoetsIndex++;
          updatePoetsSection();
        });

        prevPoetsButton.addEventListener('click', () => {
          currentPoetsIndex--;
          updatePoetsSection();
        });

        function updatePoetsSection() {
          poetsSection.forEach((poet, index) => {
            if (index === currentPoetsIndex) {
              poet.classList.add('active');
            } else {
              poet.classList.remove('active');
            }
          });

          if (currentPoetsIndex >= poetsSection.length-1) {
            nextPoetsButton.classList.remove('active');
          } else {
            nextPoetsButton.classList.add('active');
          }

          if (currentPoetsIndex <= 0) {
            prevPoetsButton.classList.remove('active');
          } else {
            prevPoetsButton.classList.add('active');
          }
        }
    },
    poetsList: function () {
        const nextButton = document.querySelector('.poets-list-wrapper .see-more-button');
        if (!nextButton) {
            return;
        }

        const poetsList = nextButton.parentElement.querySelectorAll('.poets-list');

        nextButton.addEventListener('click', () => {
            nextButton.classList.toggle('show-less');
            poetsList.forEach((poet) => {
                poet.classList.toggle('poets-list--second-part');
            });
        });
    }
}
